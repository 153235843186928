.form__group {
    position: relative;
    padding: 15px 0 0;
    margin-top: 10px;
    width: 50%;
}
  
.form__field {
    font-family: inherit;
    width: 180%;
    border: 0;
    border-bottom: 2px solid #9b9b9b;
    outline: 0;
    font-size: 1rem;
    color: var(--off-white);
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
}
  
.form__field::placeholder {
    color: transparent;
}
  
.form__field:placeholder-shown ~ .form__label {
    font-size: 1.3rem;
    cursor: text;
    top: 1.4rem;
}
  
.form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: #9b9b9b;
}
  
.form__field:focus ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: #11998e;
    font-weight: 700;  
}
  
.form__field:focus, textarea:focus {
    padding-bottom: 6px;  
    font-weight: 700;
    border-width: 3px;
    border-image: linear-gradient(to right, var(--teal), var(--neon-lime));
    border-image-slice: 1;
}
  
/* reset input */
.form__field:required,
.form__field:invalid {
    box-shadow: none;
}
  
#grid-contact-form {
    display: grid;
    max-width: 22rem;
    margin: auto;
    margin-top: 3rem;
    grid-auto-columns: minmax(0, 1fr);
    grid-template-areas:
      "ctitle"
      "cname"
      "cemail"
      "cmessage"
      "csubmit";
    column-gap: 2rem;
}
  
#contact-form-title {
    grid-area: ctitle;
}
  
#contact-form-name {
    grid-area: cname;
    margin-bottom: 0.5rem;
}
  
#contact-form-email {
    grid-area: cemail;
    margin-bottom: 0.5rem;
}
  
#contact-form-message {
    grid-area: cmessage;
}
  
#contact-form-submit {
    grid-area: csubmit;
    
    place-self: center;
}
  
input[type=submit] {
    padding:0.5rem 1rem;
    width: auto;
    margin-top: 0.8rem;
    background: linear-gradient(to right, var(--teal), var(--neon-lime));
    border:0 none;
    cursor:pointer;
    -webkit-border-radius: 5px;
    border-radius: 5px;
}
  
input[type=submit]:hover {
    background: linear-gradient(to right, var(--teal), var(--light-purple));
}
  
textarea {
    width: 100%;
    height: 9.5rem;
    padding: 0.7rem;
    margin: 0.3rem 0;
    color: var(--off-white);
    font-size: 1rem;
    box-sizing: border-box;
    border-color: transparent;
    background-color: #f8f8f80d;
    resize: none;
    outline: none;
}

@media screen and (min-width: 896px) {

    
    #grid-contact-form {
      display: grid;
      margin-top: 3rem;
      max-width: inherit;
      grid-auto-columns: minmax(0, 2fr);
      grid-template-areas:
        "ctitle ctitle ctitle"
        "cname cemail cemail"
        "cmessage cmessage cmessage"
        ". csubmit .";
      column-gap: 1rem;
    }
  
}
  