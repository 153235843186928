:root {
  --off-white: #FAF9F6;
  --purple: #682AE9;
  --light-purple: #956eeb;
  --black: #0E1212;
  --light-black: #1a1c1c;
  --active: #595959;
  --blue: #4477CE;
  --yellow: #F0EB8D;
  --green: #9AE66E;
  --android-green: #3DDC84;
  --peach: #F67E7D;
  --teal: #11998E;
  --neon-lime: #38EF7D;
}

body, button {
  font-family: 'Roboto Mono', monospace;
  color: var(--off-white);
  font-size: 100%;
}

@media(prefers-color-scheme: dark) {
  :root {
    --off-white: #FAF9F6 !important;
    --purple: #682AE9 !important;
    --light-purple: #956eeb !important;
    --black: #0E1212 !important;
    --light-black: #1a1c1c !important;
    --active: #595959 !important;
    --blue: #4477CE;
    --yellow: #F0EB8D;
    --green: #9AE66E;
    --android-green: #3DDC84;
    --peach: #F67E7D;
  }
}

.parent {
  position: fixed;
  top: 0;
  left: 0;
  margin: auto;
  width: 100%;
  background: var(--black);
  z-index: 999;
}

body {
  background: var(--black);
  overflow-x: hidden;
}

html {
  height: 100%;
}

a {
  text-decoration: none;
}

h1 {
  font-size: 2rem;
}

.space {
  height: 1rem;
}

.page-title {
  margin-left: 1rem;
}

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0); 
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap; 
  width: 1px;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  max-width: 80rem;
  margin: 0 auto;
  font-size: 1.2rem;
}

.primary-nav {
  list-style: none;
  padding: 0;
  margin: 0;
}

.purp {
  color: var(--purple);
}

.light-purp {
  color: var(--light-purple);
}

.blue {
  color: var(--blue);
}

.yllw {
  color: var(--yellow);
}

.green {
  color: var(--green);
}

.android-green {
  color: var(--android-green);
}

.peach {
  color: var(--peach);
}

.header-nav-bttns {
  display: flex;
  gap: 1rem;
  align-items: center;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.header-current {
  padding: 1rem;
  z-index: 999;

  animation: 0.6s ease-out 0s 1 slideInFromLeft;
}

.mobile-nav-toggle {
  display: none;
}

.social-icon {
  font-size: 1.35em;
  padding-right: 1rem;
}

@media (max-width: 35em) {
  .primary-nav {
    position: fixed;
    flex-direction: column;
    inset: 0 0 0 0%;
    background: hsla(0, 0%, 0%, 0.8);
    padding: 1.3rem 1rem;

    transform: translateX(100%);
    transition: transform 350ms ease-in-out;
  }

  .primary-nav[data-visible='true'] {
    transform: translateX(0%);
  }
  
  .header-nav-bttns {
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0 1rem 4rem;
  }

  .mobile-nav-toggle {
    display: block;
    position: absolute;
    z-index: 9999;
    background-color: transparent;
    background-image: url("images/menu-burguer.svg");
    background-repeat: no-repeat;
    border: 0;
    width: 2rem;
    aspect-ratio: 1;
    top: 0.6rem;
    right: 1rem;
  }

  .mobile-nav-toggle[aria-expanded='true'] {
    background-image: url("images/close-icon.svg");
    background-color: transparent;
  }

  .social-icon {
    padding-right: 0rem;
  }
}

.hBttn {
  color:var(--off-white);
  transition: color .4s ease-out;
}

a.active .hBttn {
  color: var(--active);
}

.hBttn:hover {
  color: var(--purple);
}

.section-container {
  display: block;
  max-width: 70rem;
  align-items: center;
  margin: 2rem 2rem;
}

.tooltip {
  position: relative;
  
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 16rem;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: -260%;
}


.tooltip:hover .tooltiptext {
  visibility: visible;
}

#welcome-img {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  position: relative;
  max-width: 400px;
  margin: 3rem auto;
}

#formdiv {
  margin: auto;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup-content {
  background-color: var(--active);
  padding: 20px;
  margin: 1rem;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.popup button {
  background: linear-gradient(to right, var(--teal), var(--neon-lime));
  color: var(--light-black);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.popup button:hover {
  background: linear-gradient(to right, var(--teal), var(--light-purple));
}


#grid-cv-page {
  display: grid;
  margin-top: 3rem;
  margin: 0.2rem 0.2rem;
  max-width: inherit;
  text-align: justify;
  grid-auto-columns: minmax(0, 1fr);
  grid-template-areas:
    "header"
    "profile"
    "skills"
    "experience"
    "education"
    "research"
    "hobbies" ;
  column-gap: 2rem;
}

#cv-header {
  grid-area: header;
}

#cv-profile {
  grid-area: profile;
}

#cv-experience {
  grid-area: experience;
}

#cv-education {
  grid-area: education;
}

#cv-contacts {
  grid-area: contacts;
}

#cv-skills {
  grid-area: skills;
}

#cv-scholarship {
  grid-area: research;
}

#cv-hobbies {
  grid-area: hobbies;
}



body {
  margin:0px;
}

.project-dark-block {
  background:var(--black);
  padding-top: 10%;
  padding-bottom: 1%;
  color:var(--off-white);
}
.project-light-block {
  background:var(--light-black);
  width: 100%;
  padding-top: 1%;
  padding-bottom: 1%;
  color:var(--off-white);
  align-items: center;
}

.linked-project-title {
  display: block;
  align-items: center;
}

.project-container {
  width: 90%;
  margin-left: 1.5rem;
  margin-right: auto;
  position: relative;
}

.btn-toggle {
  margin-top: 4px;
}

.image-float-right {
  max-width: 20rem;
  border-radius: 4%;
  margin: 0 auto;
}

.image-container {
  
  text-align: center;
}

.image-float-left {
  max-width: 20rem;
  border-radius: 4%;
  margin: 0 auto;
}

/*------------------*/
/*  SKEW CLOCKWISE  */
/*------------------*/
.skew-cc{
  width:100%;
  height:100px;
  position:relative;
  left:0px;
  background: linear-gradient(to right bottom, var(--black) 49%, var(--light-black) 50%),    linear-gradient(-45deg, #ffffff 16px, #000 0);
  overflow: hidden;
}

.btn-toggle {
  position: absolute;
  top: 2rem;
  left: 50%;
  /* Added rotation transform inline */
  padding: 10px 20px; /* Adjust padding as needed */
  font-size: 16px; /* Adjust font size as needed */
  background-color: #ffffff00; /* Button background color */
  color: var(--off-white); /* Button text color */
  border: none;
}


/*-------------------------*/
/* SKEW COUNTER CLOCKWISE  */
/*-------------------------*/
.skew-c{
  width:100%;
  height:100px;
  position:absolute;
  left:0px;
  background: linear-gradient(to left bottom, var(--light-black) 49%, var(--black) 50%);
}

.ignes-project-image {
  width: 100%;
}


@media screen and (max-width: 415px) {
  .section-container {
    margin: 2rem 0.5rem;
  }

  .ul-cv {
    padding-left: 1rem;
  }
}

@media screen and (min-width: 896px) {

  .section-container {
    display: flex;
    margin: 2rem auto;
  }

  .body-text {
    padding: 0rem 2rem 0rem 2rem;
    margin-bottom: 2rem;
  }

  #welcome-img {
    margin: 2rem 2rem;
  }

  .page-title {
    margin-left: 10rem;
  }

  #grid-cv-page {
    display: grid;
    margin: 1rem;
    max-width: inherit;
    grid-auto-columns: minmax(0, 1fr);
    grid-template-areas:
      "header header"
      "profile experience"
      "skills education"
      "research education"
      "hobbies ." ;
    column-gap: 3rem;
  }

  #grid-contact-form {
    display: grid;
    margin-top: 3rem;
    max-width: inherit;
    grid-auto-columns: minmax(0, 2fr);
    grid-template-areas:
      "ctitle ctitle ctitle"
      "cname cemail cemail"
      "cmessage cmessage cmessage"
      "csubmit csubmit csubmit";
    column-gap: 1rem;
  }

  .project-container {
    margin: 2rem auto;
    max-width: 70rem;
  }

  .linked-project-title {
    display: flex;
  }

  .project-link {
    margin-left: 0.5rem;
  }

  .project-dark-block {
    padding-top: 5%;
    padding-bottom: 1%;
  }
  .project-light-block {
    padding-top: 1%;
    padding-bottom: 1%;
  }

  .ignes-project-image {
    width: 50%;
  }

  .project-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .image-float-right {
    max-width: 20rem;
    float: right;
    border-radius: 4%;
    margin-left: 1rem;
  }

  .image-float-left {
    max-width: 20rem;
    float: left;
    border-radius: 4%;
    margin-right: 1rem;
  }

  #push-down {
    margin-top: 14rem;
  }
}

#avatar {
  grid-column: 1 / 2;
  grid-row: 1;
  z-index: 1;
  border-radius:50%;
  width: 150%;
  margin-top: 3rem;
}

#earth {
  width: 100%;
  grid-column: 2 / -1;
  grid-row: 1;
}


.resize-animation-stopper * {
  animation: none !important;
  transition: none !important;
}